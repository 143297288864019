/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class Const {
  //位置情報取得時関連
  static readonly FAILED_NO_PERMISSION = 'FAILED_NO_PERMISSION';
  static readonly SUCCESS_GET_USER_POSITION = 'SUCCESS_GET_USER_POSITION';
  static readonly ERROR_NOT_SUPPORT = 'ERROR_NOT_SUPPORT';
  static readonly FAILED_GET_USER_POSITION = 'FAILED_GET_USER_POSITION';
  static readonly RETRY_GET_USER_POSITION = 'RETRY_GET_USER_POSITION';
  static readonly GPS_GUIDE_URL = 'https://www.smamobi.jp/call/smartphone/';
  static readonly IOS_GPS_GUIDE_URL = 'https://www.youtube.com/watch?v=bHku6sEmAic';
  static readonly ANDROID_GPS_GUIDE_URL = 'https://www.youtube.com/watch?v=bn_SaxJRXuQ';
  static readonly CLOSE_BUTTON = 'CLOSE_BUTTON';
  static readonly RETRY_GET_USER_POSITION_BUTTON = 'RETRY_GET_USER_POSITION_BUTTON';
  static readonly SELECT_PU_STATION_FROM_MAP_BUTTON = 'SELECT_PU_STATION_FROM_MAP_BUTTON';
  static readonly IS_RETRY_GET_GPS_FLAG = 'IS_RETRY_GET_GPS_FLAG';

  // モーダルサイズ
  static readonly MODAL_FULL: string = 'modal-full';                                          // 画面全体に表示するモーダル
  static readonly MODAL_FULL_FOOT: string = 'modal-full-foot';                                // 画面全体に表示するモーダル、フッター有り
  static readonly MODAL_FULL_FOOT_BOTTOM_UP: string = 'modal-full-foot-bottom-up';            // 画面全体に表示するモーダル、フッター有り(フッター高さ調整用)
  static readonly MODAL_LARGE: string = 'modal-large';                                        // 画面9割程度の高さのモーダル
  static readonly MODAL_LARGE_NOSHADOW: string = 'modal-large-noshadow';                      // 画面9割程度の高さのモーダル（影無し）
  static readonly MODAL_MIDDLE: string = 'modal-middle';                                      // 画面6割程度の高さのモーダル
  static readonly MODAL_MIDDLE_HIGH: string = 'modal-middle-high';                            // 画面7割程度の高さのモーダル
  static readonly MODAL_SMALL: string = 'modal-small';                                        // 画面5～6割程度の高さのモーダル
  static readonly MODAL_HALF: string = 'modal-half';                                          // 画面5割程度の高さのモーダル
  static readonly MODAL_HALF_MP: string = 'modal-half-mp';                                    // MPのIMG中間サイズのｎ高さのモーダル
  static readonly MODAL_HALF_WITH_WALK_INFO: string = 'modal-half with-walk-info';            // 徒歩情報の行を含め、画面5割程度の高さのモーダル
  static readonly MODAL_MINI: string = 'modal-mini';                                          // 画面2～3割程度の高さのモーダル
  static readonly MODAL_TINY: string = 'modal-tiny';                                          // 画面2割程度の高さのモーダル
  static readonly MODAL_TINY_WITH_WALK_INFO: string = 'modal-tiny with-walk-info';            // 画面2割程度の高さのモーダルに、徒歩情報の行を追加
  static readonly MODAL_FOOTER_BALLOON: string = 'modal-footer-balloon';                      // footerから引き出しで表示するモーダル
  static readonly MODAL_FOOTER_BALLOON_BOTTOM_UP: string = 'modal-footer-balloon-bottom-up';  // footerから引き出しで表示するモーダル(フッター高さ調整用)
  static readonly MODAL_FOOTER_BALLOON_MIDDLE: string = 'modal-footer-balloon-middle';        // footerから引き出しで表示するモーダル(ボタン3段表示)
  static readonly MODAL_FOOTER_MIDDLE: string = 'modal-footer-middle';                        // 画面下から4割程度の高さのモーダル

  // EVENT
  static readonly EVENT_HELP: string = 'helpEvent';
  static readonly EVENT_FLAG: string = 'flagEvent';
  static readonly EVENT_USER: string = 'userEvent';
  static readonly EVENT_CANCEL: string = 'cancelEvent';
  static readonly EVENT_ARRIVED: string = 'arrivedEvent';
  static readonly EVENT_ARRIVED_CHECK: string = 'arrivedCheckEvent';
  static readonly EVENT_INFO: string = 'infoEvent';
  static readonly EVENT_RATE: string = 'rateEvent';
  static readonly EVENT_BOOKING: string = 'bookingconfirmed';
  static readonly EVENT_BOOKING_NEW: string = 'newBookingConfirmed';
  static readonly EVENT_CHANGE_STATE: string = 'changeStateEvent';
  static readonly EVENT_MARK_RATE: string = 'markRateEvent';
  static readonly EVENT_FINISH: string = 'finishEvent';
  static readonly EVENT_FEEDBACK: string = 'feedbackEvent';
  static readonly EVENT_RATE_MINUS: string = 'rateMinusEvent';
  static readonly EVENT_RATE_PLUS: string = 'ratePlusEvent';
  static readonly EVENT_CANCEL_TRIP: string = 'cancelTripEvent';
  static readonly EVENT_WAIT_NEXT_TAXI: string = 'waitNextTaxiEvent';
  static readonly EVENT_CHANGE_INPUT_FORM: string = 'changeInputFormEvent';
  static readonly EVENT_WAIT_NEXT_TAXI_GO_MENU: string = 'waitNextTaxiGoMenu';
  static readonly EVENT_CLAER_PREDICTIONS_MAIN_LIST:
    string = 'clearPredictionsMainListEvent'; //画面遷移上、個別のイベント定義したほうが管理が簡単であったため、MainlistとMapで分割した。
  static readonly EVENT_CLAER_PREDICTIONS_MAP: string = 'clearPredictionsMapEvent';
  static readonly EVENT_PAN: string = 'panEvent';
  static readonly EVENT_DIRECTION_UP: string = 'panUpEvent';
  static readonly EVENT_DIRECTION_DOWN: string = 'panDownEvent';
  static readonly EVENT_SIDEMENU_UPDATE: string = 'sidemenuUpdateEvent';

  // TODO: 使用しないので削除予定
  static readonly EVENT_CHANGE_START_SCREEN_VIEW_TYPE: string = 'changeStartScreenViewTypeEvent';

  static readonly EVENT_SELECT_RIDE_POINT: string = 'selectRidePoint';

  // STATE
  static readonly STATE_START_SCREEN = 'T2StartScreenMapPage';
  static readonly STATE_TRAVEL_PROPOSAL = 'T11TravelProposalPage';
  static readonly STATE_WALK_TO_PICKUP = 'T20WalkToPickUpPage';
  static readonly STATE_WALK_TO_PICKUP_USER_REACHED = 'T20WalkToPickUpPageUserReached';
  static readonly STATE_LOOKING_FOR_TAXI = 'LookingForTaxiPage';
  static readonly STATE_BOOKING_CONFIRMED = 'BookingConfirmedPage';
  static readonly STATE_TAXI_HAD_TO_LEAVE_POPUP = 'TaxiHadToLeavePopUpPage';
  static readonly STATE_WELCOME_ON_BOARD = 'WelcomeOnBoardPage';
  static readonly STATE_RIDING = 'T21RidingPage';
  static readonly STATE_DROP_OFF_ARRIVAL = 'DropOffArrivalPage';
  static readonly STATE_ARRIVED = 'ArrivedPage';
  //static readonly STATE_WALK_GUIDANCE_TO_DEST = 'T21cWalkGuidanceToDestPage';
  static readonly STATE_TRAVEL_OPTIONS = 'T12TravelOptionsPage';
  static readonly STATE_RATING = 'T24cRatingPage';
  static readonly STATE_MAP = 'map';

  // START_SCREEN_VIEW_TYPE
  static readonly START_SCREEN_VIEW_TYPE_PU_NEAR_STOP_POINT = 'PU_NEAR_STOP_POINT'; // 乗車地を選ぶ：近地乗車地
  static readonly START_SCREEN_VIEW_TYPE_PU_MAP_STOP_POINT = 'PU_MAP_STOP_POINT'; // 乗車地を選ぶ：マップ選択
  static readonly START_SCREEN_VIEW_TYPE_PU_DECISION_STOP_POINT = 'PU_DECISION_STOP_POINT'; // 乗車地を選ぶ：マップ選択決定
  static readonly START_SCREEN_VIEW_TYPE_DO_MAIN_STOP_POINT = 'DO_MAIN_STOP_POINT'; // 降車地を選ぶ：代表降車地一覧
  static readonly START_SCREEN_VIEW_TYPE_CONFIRM_VACANCY_SYSTEM = 'CONFIRM_VACANCY_SYSTEM'; // 満空情報提供店舗から選ぶ: 満空情報確認一覧
  static readonly START_SCREEN_VIEW_TYPE_FACILITY_DETAILS = 'FACILITY_DETAILS'; // 満空情報提供店舗から選ぶ: 施設詳細画面
  static readonly START_SCREEN_VIEW_TYPE_DO_GROUP_STOP_POINT = 'DO_GROUP_STOP_POINT'; // 目的地を選ぶ：区画選択
  static readonly START_SCREEN_VIEW_TYPE_DO_MAP_STOP_POINT = 'DO_MAP_STOP_POINT'; // 目的地を選ぶ：マップ選択
  static readonly START_SCREEN_VIEW_TYPE_DO_DECISION_STOP_POINT = 'DO_DECISION_STOP_POINT'; // 目的地を選ぶ：マップ選択決定
  static readonly START_SCREEN_VIEW_TYPE_PUDO_DECISION_STOP_POINT = 'PUDO_DECISION_STOP_POINT'; // 乗車地を選ぶ：乗降地決定済み（車を呼ぶ）

  //rams追加ここから
  static readonly START_SCREEN_VIEW_TYPE_DO_CHOOSE_HOW_TO_SEARCH = 'DO_CHOOSE_HOW_TO_SEARCH'; //他の降車地を選ぶ : 検索方法
  static readonly START_SCREEN_VIEW_TYPE_DO_CHOOSE_INITIAL_COLUMN = 'DO_CHOOSE_INITIAL_COLUMN'; //頭文字の行を選ぶ
  static readonly START_SCREEN_VIEW_TYPE_DO_CHOOSE_INITIAL = 'DO_CHOOSE_INITIAL'; //頭文字を選ぶ
  static readonly START_SCREEN_VIEW_TYPE_DO_CHOOSE_INITIAL_STOP_POINT = 'DO_CHOOSE_INITIAL_STOP_POINT'; //頭文字
  static readonly START_SCREEN_VIEW_TYPE_DO_CHOOSE_INITIAL_STOP_POINT_FROM_KEYWORD = 'DO_CHOOSE_INITIAL_STOP_POINT_FROM_KEYWORD'; //頭文字
  static readonly START_SCREEN_VIEW_TYPE_DO_CHOOSE_ADDRESSS_LARGE = 'DO_CHOOSE_ADDRESSS_LARGE'; //住所から探す(大字)
  static readonly START_SCREEN_VIEW_TYPE_DO_CHOOSE_ADDRESSS_SMALL = 'DO_CHOOSE_ADDRESSS_SMALL'; //住所から探す(小字)
  static readonly START_SCREEN_VIEW_TYPE_DO_CHOOSE_ADDRESSS_STOP_POINT = 'DO_CHOOSE_ADDRESSS_STOP_POINT'; //住所から探す(小字)
  static readonly START_SCREEN_VIEW_TYPE_DO_CHOOSE_HISTORY_STOP_POINT = 'DO_CHOOSE_HISTORY_STOP_POINT'; //住所から探す(小字)
  static readonly START_SCREEN_VIEW_TYPE_CANNOT_GO_BACK_HOME = 'CANNOT_GO_BACK_HOME'; //ハブへ行く必要がある
  static readonly START_SCREEN_VIEW_TYPE_BY_WALK = 'BY_WALK'; //徒歩で行く
  static readonly START_SCREEN_VIEW_TYPE_RETRY_REGISTER_HOMESTAION = 'RETRY_REGISTER_HOMESTAION';

  //rams追加ここまで
  // T13 Booking Confirmedモーダルの文言切り替え
  static readonly BOOKING_CONFIRMED_TYPE_NORMAL: number = 0;
  static readonly BOOKING_CONFIRMED_TYPE_NEW: number = 1;

  // storage key
  static readonly STORAGE_KEY_USER_ID: string = 'user_id';
  static readonly STORAGE_KEY_LOGIN_ID: string = 'login_id';
  static readonly STORAGE_KEY_LOGIN_PASSWORD: string = 'login_password';
  static readonly STORAGE_KEY_USER_ICON: string = 'user_icon';
  static readonly STORAGE_KEY_USER_NAME: string = 'user_name';
  static readonly STORAGE_KEY_REQUESTTABLE_RESERVATION: string = 'requestableReservation';
  static readonly STORAGE_KEY_VEHICLE_ID: string = 'vehicle_id';
  static readonly STORAGE_KEY_TOKEN: string = 'token';
  static readonly STORAGE_KEY_LANGUAGE: string = 'language';
  static readonly STORAGE_KEY_TRIPHISTORY: string = 'tripHistory';
  static readonly STORAGE_KEY_TRAVEL_OPTION: string = 'travelOption';
  static readonly STORAGE_KEY_TRIP_DESTINATION: string = 'tripDestination';
  static readonly STORAGE_KEY_TRIP_ORIGIN: string = 'tripOrigin';
  static readonly STORAGE_KEY_VEHICLE_ARRIVED_MINUTE: string = 'vehicleArrivedMinute';
  static readonly STORAGE_KEY_QUESTION_POINTS: string = 'questionPoints';
  static readonly STORAGE_KEY_STATION_HISTORY: string = 'stationHisory';
  static readonly STORAGE_KEY_LAST_OPEN_APP_DATE: string = 'lastOpenAppDate';
  static readonly STORAGE_KEY_IS_UPDATE_FAILED: string = 'faildUpdate';

  //エラーモーダル
  static readonly ERROR_MODAL_TITLE_NO_ACCESS = 'no_access'; //アクセス不可
  static readonly ERROR_MODAL_TEXT_RETRY_ACCESS = 'retry_access'; //再アクセス
  static readonly ERROR_MODAL_TITLE_CONFIRM_COMMUNICATION = 'confirm_communication'; //通信状態確認

  // Monthly Name
  static readonly MONTHLY_NAME = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  // User GPS Location
  static readonly USER_GPS_LOCATION: string = 'userGPSLocation';

  // User State
  /** 個別にユーザステータスを判定する必要がない場合に利用する */
  static readonly USER_STATE_ANY: string = 'any';
  /** 配車依頼受付中(1). 旧 : 配車可能(1) / ENABLED. */
  static readonly USER_STATE_ACCEPTING_DISPATCH_ORDER: string = '1';
  /** 乗降場所計画指示中(2). 旧 : 配車可能(1) / ENABLED. / NIMO未使用 */
  static readonly USER_STATE_USER_STATE_BOOKING: string = '2';
  /** 配車計画依頼中(3). 旧 : 配車可能(1) / ENABLED. / NIMO未使用 */
  static readonly USER_STATE_DISPATCH_PLANNING_ORDER: string = '3';
  /** 配車仮予約依頼中(4). 旧 : 配車可能(1) / ENABLED. / NIMO未使用 */
  static readonly USER_STATE_DISPATCH_TENTATIVE_RESERVATION_ORDER: string = '4';
  /** 配車予約確定確認中(5). 旧 : 配車可能(1) / ENABLED. / NIMO未使用 */
  static readonly USER_STATE_DISPATCH_RESERVATION_CHECK_CONFIRMATION: string = '5';
  /** 配車予約確定依頼中(6). 旧 : 配車可能(1) / ENABLED. / NIMO未使用 */
  static readonly USER_STATE_DISPATCH_RESERVATION_FIXED_ORDER: string = '6';
  /** 乗車地誘導中(7). 旧 : 配車中(2) / ALLOCATE_CARS. */
  static readonly USER_STATE_GUIDANCE_RIDE_PLACE: string = '7';
  /** ユーザ待機中(8). 旧 : ユーザMP到着(3) / ARRIVE_AT_MP_USER. */
  static readonly USER_STATE_WAITING_USER: string = '8';
  /** 車両待機中(9). 旧 : 車両MP到着(8) / ARRIVE_AT_MP_VEHICLE. */
  static readonly USER_STATE_WAITING_VEHICLE: string = '9';
  /** 認証待機中(10). 旧 : MP到着(9) / ARRIVE_AT_MP_USER_AND_VEHICLE. */
  static readonly USER_STATE_WAITING_AUTHENTICATION: string = '10';
  /** ユーザ認証中(11). 旧 : MP到着(9) / ARRIVE_AT_MP_USER_AND_VEHICLE. / NIMO未使用 */
  static readonly USER_STATE_USER_AUTHENTICATION: string = '11';
  /** 乗り込み待機中(12). 旧 : BLE認証完了(10) / BLE_AUTH. / NIMO未使用 */
  static readonly USER_STATE_WAITING_RIDE: string = '12';
  /** 乗り込み中(13). 旧 : 乗込中(ドアアンロック済)(11) / DOOR_UNLOCK. */
  static readonly USER_STATE_RIDING: string = '13';
  /** 目的地移動中(14). 旧 : 乗車中(4) / MOVING. */
  static readonly USER_STATE_MOVING_DESTINATION: string = '14';
  /** 降車地付近接近中(15). 旧 : DP付近(5) / NEAR_DP. */
  static readonly USER_STATE_ACCESS_IN_DROP_OFF_PLACE_NEAR: string = '15';
  /** 降車待機中(16). 旧 : DP到着(6) / ARRIVE_AT_DP. */
  static readonly USER_STATE_WAITING_DROP_OFF: string = '16';
  /** 降車中(17). 旧 : 降車中(12) / GETTING_OFF. */
  static readonly USER_STATE_IN_DROP_OFF: string = '17';
  /** 目的地誘導中(18). 旧 : 閉扉後(13) / DOOR_LOCK. */
  static readonly USER_STATE_GUIDANCE_DESTINATION: string = '18';
  /** 乗車タイムアウト(19). 旧 : MPタイムオーバー(7) / TIMEOVER. */
  static readonly USER_STATE_RIDE_TIMEOUT: string = '19';
  /** 目的地到着(20). 旧 : 閉扉後(13) / DOOR_LOCK. / NIMO未使用 */
  static readonly USER_STATE_ARRIVED_DESTINATION: string = '20';
  /** 車両アクシデント発生中(99). 旧:なし. */
  static readonly USER_STATE_VEHICLE_ACCIDENT_OCCURRENCE: string = '99';

  // requestableReservation
  /** 乗車のみ. */
  static readonly REQUESTTABLE_RESERVATION_RIDE_ONLY = 'ride_only';
  /** 配送のみ. */
  static readonly REQUESTTABLE_RESERVATION_DELIVERY_ONLY = 'delivery_only';

  // Delay Change GUI
  static readonly DELAY_CHANGE_GUI_MILLISECOND: number = 2000;

  // THANK YOU MESSAGE Component Show Time
  static readonly SHOW_TIME_THANK_YOU_MESSAGE: number = 3000;

  // UserStatusReceiveServlet Request Status
  static readonly REQUEST_STATUS_DISPATCH: number = 1;
  static readonly REQUEST_STATUS_DISPATCH_CANCEL: number = 2;
  static readonly REQUEST_STATUS_CONFIRM: number = 4;

  // http-request code
  static readonly HTTP_REQUEST_CODE_FAILURE: number = -1;
  static readonly HTTP_REQUEST_CODE_SUCCESS: number = 0;

  // http-response code
  static readonly HTTP_RESPONSE_RESULT_FAILURE: number = 0;
  static readonly HTTP_RESPONSE_RESULT_SUCCESS: number = 1;

  // http-response status code
  static readonly HTTP_RESPONSE_STATUS_OK: number = 200;

  // Leafletの目的地到着時のズーム
  static readonly MAP_STATE_ARRIVED_ZOOM: number = 16;

  // Leafletの乗車地降車地確認時のズーム
  static readonly MAP_STATE_DECIDE_ZOOM: number = 18;
  static readonly MAP_STATE_PUDO_ZOOM: number = 14;
  static readonly MAP_STATE_NEAR_ZOOM: number = 17;
  static readonly MAP_STATE_BOOKING_ZOOM: number = 15;

  // 目的位置の範囲(メートル)
  static readonly ARRIVAL_RANGE: number = 80;

  /** アンケートID：3(Rate taxi option) */
  static readonly QUESTION_ID_RATE_TAXI_OPTION: string = '3';
  /** アンケートID：4(Arrived) */
  static readonly QUESTION_ID_ARRIVED: string = '4';
  /** アンケートID：5(Rating) */
  static readonly QUESTION_ID_RATING: string = '5';

  // アンケート(answer_no)
  // n段階式
  static readonly QUESTION_ANSWER_NO_STAGED_FORMULA: string = '0';

  // 料金　0(固定値)
  static readonly PRICE_ZERO: number = 0;
  // 料金　100(固定値)
  static readonly PRICE_ONE_HUNDRED: number = 100;

  // 報酬　2(固定値)
  static readonly TRAVEL_POINT: string = '2';

  // Vehicle PolyLine Color
  static readonly POLYLINE_COLOR_VEHICLE_PINK: string = '#f66df0';
  static readonly POLYLINE_COLOR_VEHICLE_BLUE_START: string = '#d6f6ff';
  static readonly POLYLINE_COLOR_VEHICLE_BLUE_END: string = '#0051c9';
  static readonly POLYLINE_COLOR_VEHICLE_YELLOW_START: string = '#fee000';
  static readonly POLYLINE_COLOR_VEHICLE_YELLOW_END: string = '#f18900';

  static readonly POLYLINE_COLOR_VEHICLE_BLUE: string = '#20a7e6';
  static readonly POLYLINE_COLOR_PUDO_BLUE: string = '#23b9ff';

  // DoughnutChart全体率
  static readonly DOUGHNUT_CHART_PERCENT = 1000;
  // 車両到着初期値
  static readonly DOUGHNUT_ARRIVED_REM = 1000;

  /** 乗り遅れた時の次の車両待ち時間 */
  static readonly WATI_TAXI_TIME: string = '05';

  // 通知音
  static readonly AUDIO_ID_NOTIFICATION: string = 'AUDIO_ID_NOTIFICATION';

  // panイベント時適用CSSクラス
  static readonly PAN_CLASS_FOOTER4: string = 'footer4-carcircle_area_up';
  static readonly PAN_CLASS_M6_ARRIVED_CHECK: string = 'm6_arrived_check_area_up';
  static readonly PAN_CLASS_M8_CANCEL_TRIP: string = 'm8_cancel_trip_area_up';
  static readonly PAN_CLASS_M8_HELP_OPTION: string = 'm8_help_option_area_up';

  // ユーザーアイコン吹き出し適用CSSクラス
  static readonly USER_ICON_BALLOON_CLASS_MIDDLE: string = 'middle';
  static readonly USER_ICON_BALLOON_CLASS_SMALL: string = 'small';


  static readonly STATION_TYPE_MAIN = 1;
  static readonly STATION_TYPE_CENTRAL_KEYWORD = 2;
  static readonly STATION_TYPE_CENTRAL_NOT_KEYWORD = 3;
  static readonly STATION_TYPE_PERIPHERAL = 4;
  // アイコンシンプル表示の閾値（ズームの最大値は21）
  static readonly SIMPLE_MAP_THLESHOLD = 22;

  // 外部URL
  // eslint-disable-next-line max-len
  static readonly URL_SIGN_UP = 'https://forms.office.com/r/J26Pr1aina';
  static readonly URL_FARE_GUIDE = 'https://www.smamobi.jp/area';

  // 履歴開始日時
  static readonly HISTORY_START_DATE = '2023/01/05 00:00:00';

  //モーダルタイプ
  static readonly MODAL_TYPE_GETTING_GPS = 'gettingGPS';
  static readonly MODAL_TYPE_ERROR = 'error';
  static readonly MODAL_TYPE_GPS = 'gps';

  //コールセンターの電話番号
  static readonly CALL_CENTER_NUMBER = '0240-23-5552';

}
